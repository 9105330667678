import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Flex, Title, Label } from "../components/system"
import Blocks from "../components/Blocks"

const TermsAndConditions = ({ data }) => {
  console.log(data)
  return (
    <Layout dark>
      <Flex flexDirection="column" pt={6}>
        <Label fontSize={1} color="faded">
          Legal
        </Label>
        <Title fontSize={[6, 7]} mb={5} color="secondary">
          Terms & Conditions
        </Title>
        {data.sanityContent && (
          <Blocks px={[3, 5, 7]} data={data.sanityContent._rawContent} />
        )}
      </Flex>
    </Layout>
  )
}

export default TermsAndConditions

export const pageQuery = graphql`
  query TermsAndConditions {
    sanityContent(_id: { eq: "termsConditions" }) {
      _rawContent
    }
  }
`
